// AwsmoGroupHistoryModal.jsx

import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Typography,
    Box
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { awsService } from 'src/services/awsService';

// Register Chart.js components
ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const AwsmoGroupHistoryModal = ({ open, onClose, groupId, token }) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHistory = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await awsService.fetchAwsmoGroupHistory(groupId, token);

                // Handle the nested structure of the data
                if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                    const records = response.data[0].record;
                    if (Array.isArray(records)) {
                        const sortedRecords = records.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
                        setHistory(sortedRecords);
                    } else {
                        setError('No record found in the data.');
                        setHistory([]);
                    }
                } else if (response.error) {
                    // Handle error returned from the API
                    setError(response.error);
                    setHistory([]);
                } else {
                    // Handle unexpected data format
                    setError('Unexpected data format received.');
                    setHistory([]);
                }
            } catch (err) {
                console.error('Failed to fetch history:', err);
                setError('Failed to fetch history.');
                setHistory([]);
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchHistory();
        }
    }, [open, groupId, token]);


    // Prepare chart data
    const chartData = {
        datasets: [
            {
                label: 'Instance Count',
                data: history.map(record => ({
                    x: new Date(record.timestamp),
                    y: Array.isArray(record.instances) ? record.instances.length : 0,
                    instances: Array.isArray(record.instances) ? record.instances : []
                })),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                tension: 0.4,
                pointRadius: 5,
                pointHoverRadius: 7,
                fill: true,
            }
        ]
    };

    // Configure chart options
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            tooltip: {
                mode: 'nearest',
                intersect: false,
                callbacks: {
                    title: (tooltipItems) => {
                        const date = tooltipItems[0].parsed.x;
                        return new Date(date).toLocaleString();
                    },
                    label: (tooltipItem) => {
                        const instances = tooltipItem.raw.instances;
                        if (!Array.isArray(instances) || instances.length === 0) {
                            return 'Instances: None';
                        }
                        // Return an array where each element is a separate line in the tooltip
                        return [
                            'Instances:',
                            ...instances.map(instance => `• ${instance}`)
                        ];
                    },
                },
            },
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'PPpp',
                    displayFormats: {
                        day: 'MMM dd, yyyy',
                        hour: 'MMM dd, yyyy HH:mm',
                    },
                },
                title: {
                    display: true,
                    text: 'Timestamp',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 10,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Instance Count',
                },
                ticks: {
                    precision: 0,
                    stepSize: 1,
                },
            },
        },
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Load Balancer History</DialogTitle>
            <DialogContent>
                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress />
                        <Typography variant="body1" sx={{ ml: 2 }}>Loading history...</Typography>
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : history.length === 0 ? (
                    <Typography>No history data available.</Typography>
                ) : (
                    <Box sx={{ height: '400px' }}>
                        <Line data={chartData} options={chartOptions} />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AwsmoGroupHistoryModal;
