import React, { useState } from 'react';
import { Container, Row, Image, Button, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from 'src/redux/slices/authSlice';
import { authService } from 'src/services/authService';
import { orgService } from 'src/services/orgService';
import { awsService } from 'src/services/awsService';
import Logo from "src/assets/images/logo.svg";
import showPwdImg from 'src/assets/images/eye.svg';
import hidePwdImg from 'src/assets/images/eye_crossed.svg';
import { toast } from 'react-hot-toast';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const data = await authService.login(email, password);
            const orgData = await orgService.getOrganization(data.access);
            const awsAccountData = orgData && orgData.length > 0 ? orgData[0].aws_accounts : [];
            const clientArn = awsAccountData && awsAccountData.length > 0 ? awsAccountData[0].client_arn : null;
            const processedStatus = awsAccountData[0]?.processed_status;
            dispatch(setCredentials({ user: email, token: data.access, processedStatus: processedStatus }));

            if (orgData && orgData.length > 0) {
                if (clientArn) {
                    navigate('/');  // Navigate to dashboard if ARN exists
                    toast.success('Welcome!');
                } else {
                    navigate('/awsaccount');  // Redirect to AWS account setup if ARN is missing
                    toast.success('Please complete your AWS account setup.');
                }
            } else {
                console.log('No org data found')
                navigate('/welcome');
                toast.success('Please connect your AWS account.');
            }

        } catch (error) {
            const status = error?.response?.status;
            const detail = error?.response?.data?.detail;
            if (status === 403) {
                toast.error('Your account is not activated. Please check your email for the activation link or resend email');
                navigate('/resend-activation'); // Redirect to the resend activation page
            } else {
                toast.error(detail || 'An error occurred. Please try again later.');
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    };

    return (
        <>
            <div className='loginWrap'>
                <Container className='desh-height'>
                    <Row className='d-flex justify-content-center align-items-center desh-height'>
                        <div className='col-12 col-md-6 col-lg-7 text-center mb-p-logo'>
                            <div className='mr-login'>
                                <Image src={Logo} />
                                <p className='login-heading-sub'>Please sign in to connect your account</p>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 col-lg-5 col-xxl-4'>
                            <div className='loginBox'>
                                <h1>Sign In</h1>
                                <Form onSubmit={handleSubmit}>
                                    <FloatingLabel label="Email address" className="mb-2">
                                        <Form.Control
                                            type="email"
                                            placeholder="name@example.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel label="Password" className="mb-2 passwrap">
                                        <Form.Control
                                            type={isRevealPwd ? "text" : "password"}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <img
                                            alt={isRevealPwd ? "Hide password" : "Show password"}
                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </FloatingLabel>
                                    <div className='text-center mt-3'>
                                        <span className='f14'>Forgot your password? <Link to="/forgotpassword" className='f14 link-color'>Reset here</Link></span>
                                    </div>
                                    <Button variant="primary" className='mt-3 w-100' type="submit" disabled={loading}>
                                        {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Sign In'}
                                    </Button>
                                    <div className='text-center mt-3'>
                                        <span className='f14'>Don't have an account? <Link to="/signup/" className='f14 link-color'>Sign Up</Link></span>
                                    </div>
                                    <div className='text-center f14 mt-4 mt-md-5'>
                                        By continuing, you are agreeing to the <a href="#" className='link-color'>Privacy Policy</a> and <a href="#" className='link-color'>Terms and Conditions</a> of Awsmo
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Login;
