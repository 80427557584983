import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from 'src/redux/slices/authSlice';
import { orgService } from 'src/services/orgService';

function useCheckProcessedStatus() {
    const { token, processedStatus } = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        let interval;

        if (token) {
            const checkStatus = async () => {
                try {
                    const orgData = await orgService.getOrganization(token);
                    if (orgData && orgData.length > 0) {
                        const awsAccountData = orgData[0]?.aws_accounts;
                        const newProcessedStatus = awsAccountData[0]?.processed_status;

                        if (newProcessedStatus !== processedStatus) {
                            dispatch(setCredentials({ user: orgData.user, token: token, processedStatus: newProcessedStatus }));
                        }
                    }
                } catch (error) {
                    console.error('Failed to fetch organization data:', error);
                }
            };
            checkStatus();  // Check immediately
            interval = setInterval(checkStatus, 60000); // Check every minute
        }

        return () => interval && clearInterval(interval);
    }, [dispatch, token, processedStatus]);

    return { isProcessing: !processedStatus };
}

export default useCheckProcessedStatus;
