import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { authService } from 'src/services/authService';
import Logo from "src/assets/images/logo.svg";
import { Container, Row, Col, Image } from 'react-bootstrap';
import useDocumentTitle from 'src/hooks/useDocumentTitle';

function InvitedUser() {
    useDocumentTitle("Invitation - Awsmo");
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    useEffect(() => {
        const acceptInvitation = async () => {
            if (token) {
                try {
                    const response = await authService.acceptInvite(token);
                    if (response.redirect_url) {
                        window.location.href = response.redirect_url;
                    } else {
                        toast.error('Failed to accept the invitation. Please check the link or try again later.');
                        navigate('/login');
                    }
                } catch (error) {
                    console.log("error", error);
                    toast.error('Failed to accept the invitation. Please check the link or try again later.');
                    navigate('/login');
                }
            } else {
                navigate('/login');
            }
        };

        acceptInvitation();
    }, [dispatch, navigate, token]);

    return (
        <Container className='desh-height'>
            <Row className='d-flex justify-content-center align-items-center desh-height'>
                <Col md={12} className='text-center mb-p-logo'>
                    <Image src={Logo} alt="Logo" />
                    <p className='f21 mt-0 text-center mb-5'>Verifying...</p>
                </Col>
            </Row>
        </Container>
    );
}

export default InvitedUser;
