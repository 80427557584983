import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import { Button, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { setRegion } from 'src/redux/slices/awsAccountSlice';

const StyledButton = styled(Button)({
    borderRadius: '20px',
    background: '#fff',
    color: '#333',
    padding: '6px 12px',
    width: '100%',
    textTransform: 'none',
    boxSizing: 'border-box',
    '&:hover': {
        background: '#f5f5f5',
    },
    '& .MuiButton-endIcon': {
        marginLeft: '4px',
        color: '#333',
    }
});

const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: (prop) => prop !== 'isCurrent',
})(({ isCurrent }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    width: '100%',
    minWidth: '400px',
    fontSize: '14px',
    backgroundColor: isCurrent ? '#e0f7fa' : 'transparent',
    '& span:first-of-type': {
        marginRight: 'auto',
        color: '#333',
    },
    '& span:nth-of-type(2)': {
        color: '#666',
    },
    '&:hover': {
        backgroundColor: isCurrent ? '#b2ebf2' : '#f5f5f5',
    },
}));

const RegionDropdown = () => {
    const dispatch = useDispatch();
    const currentRegion = useSelector(state => state.awsAccount.region) || 'us-east-1';
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (regionCode) => {
        dispatch(setRegion(regionCode));
        handleClose();
    };

    const regionLabels = {
        'us-east-1': { name: 'US East (N. Virginia)', shortName: 'N. Virginia' },
        'us-east-2': { name: 'US East (Ohio)', shortName: 'Ohio' },
        'us-west-1': { name: 'US West (N. California)', shortName: 'N. California' },
        'us-west-2': { name: 'US West (Oregon)', shortName: 'Oregon' },
        'af-south-1': { name: 'Africa (Cape Town)', shortName: 'Cape Town' },
        'ap-east-1': { name: 'Asia Pacific (Hong Kong)', shortName: 'Hong Kong' },
        'ap-south-1': { name: 'Asia Pacific (Mumbai)', shortName: 'Mumbai' },
        'ap-northeast-1': { name: 'Asia Pacific (Tokyo)', shortName: 'Tokyo' },
        'ap-northeast-2': { name: 'Asia Pacific (Seoul)', shortName: 'Seoul' },
        'ap-northeast-3': { name: 'Asia Pacific (Osaka)', shortName: 'Osaka' },
        'ap-southeast-1': { name: 'Asia Pacific (Singapore)', shortName: 'Singapore' },
        'ap-southeast-2': { name: 'Asia Pacific (Sydney)', shortName: 'Sydney' },
        'ca-central-1': { name: 'Canada (Central)', shortName: 'Central' },
        'eu-central-1': { name: 'Europe (Frankfurt)', shortName: 'Frankfurt' },
        'eu-west-1': { name: 'Europe (Ireland)', shortName: 'Ireland' },
        'eu-west-2': { name: 'Europe (London)', shortName: 'London' },
        'eu-south-1': { name: 'Europe (Milan)', shortName: 'Milan' },
        'eu-west-3': { name: 'Europe (Paris)', shortName: 'Paris' },
        'eu-north-1': { name: 'Europe (Stockholm)', shortName: 'Stockholm' },
        'me-south-1': { name: 'Middle East (Bahrain)', shortName: 'Bahrain' },
        'sa-east-1': { name: 'South America (São Paulo)', shortName: 'São Paulo' },
    };
    console.log('currentRegion:', regionLabels[currentRegion].shortName);

    return (
        <>
            <StyledButton
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
            >
                {regionLabels[currentRegion].shortName}
            </StyledButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.entries(regionLabels).map(([code, { name }]) => (
                    <StyledMenuItem
                        key={code}
                        onClick={() => handleSelect(code)}
                        isCurrent={currentRegion === code}
                    >
                        <span>{name}</span>
                        <span>{code}</span>
                    </StyledMenuItem>
                ))}
            </Menu>
        </>
    );
}

export default RegionDropdown;
