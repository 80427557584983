import React from 'react';
import { Link } from 'react-router-dom';
import Logo from 'src/assets/images/logo.svg';
import 'src/style/Dashboard.css';
import useAuth from 'src/hooks/useAuth';
import NotificationDropdown from './NotificationDropdown';
import RegionDropdown from './RegionDropdown';
import ProfileDropdown from './ProfileDropdown';

const Header = () => {
  const { user, performLogout } = useAuth();

  const initial = user?.name?.charAt(0).toUpperCase();

  return (
    <div className='header-fix d-flex justify-content-between align-items-center'>
      <div className="d-flex justify-content-start align-items-center width-logo-sidebar pl-0">
        <div className="wrap-logo pl-lg-3">
          <Link to="/"><img src={Logo} className="img-fluid header-logo" alt="test" /></Link>
        </div>
      </div>
      <div className='d-flex align-items-center margin-right-custom'>
        <RegionDropdown />
        <NotificationDropdown />
        <ProfileDropdown initial={initial} performLogout={performLogout} />
      </div>
    </div >
  );
}

export default Header;
