// RoutesComponent.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import Dashboard from 'src/components/dashboard/Dashboard';
import Login from 'src/components/auth/Login';
import ResendActivation from 'src/components/auth/ResendActivation';
import SignUp from 'src/components/auth/SignUp';
import ForgotPassword from 'src/components/auth/ForgotPassword';
import ResetPassword from 'src/components/auth/ResetPassword';
import NotFound from 'src/components/info/NotFound';
import Verified from 'src/components/auth/Verified';
import Welcome from 'src/components/auth/Welcome';
import AWSAccount from 'src/components/dashboard/AWSAccount';
import { Toaster } from 'react-hot-toast';
import DashIDAwsmo from 'src/components/dashboard/DashIDAwsmo';
import InstanceDetail from 'src/components/dashboard/InstanceDetail';
import AccountAWSID from 'src/components/dashboard/AccountAWSID';
import AwsmoGroups from 'src/components/dashboard/AwsmoGroups';
import CreateAwsmoGroup from 'src/components/dashboard/CreateAwsmoGroup';
import GroupDetail from 'src/components/dashboard/GroupDetail';
import MaintenanceGroups from 'src/components/dashboard/MaintenanceGroups';
import Reports from 'src/components/dashboard/Reports';
import InvitedUser from 'src/components/auth/InvitedUser';

const RoutesComponent = () => {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/login" element={<PublicRoute component={Login} />} />
        <Route path="/verify-invite" element={<PublicRoute component={InvitedUser} />} />
        <Route path="/resend-activation" element={<PublicRoute component={ResendActivation} />} />
        <Route path="/signup" element={<PublicRoute component={SignUp} />} />
        <Route path="/forgotpassword" element={<PublicRoute component={ForgotPassword} />} />
        <Route path="/resetpassword/:uid/:token" element={<PublicRoute component={ResetPassword} />} />
        <Route path="/activate/:uid/:token" element={<Verified />} />
        <Route path="/welcome" element={<ProtectedRoute component={Welcome} />} />
        <Route path="/awsaccount" element={<ProtectedRoute component={AWSAccount} needsData={true} />} />

        {/* Nested Routes within Dashboard */}
        <Route path="/" element={<ProtectedRoute component={Dashboard} needsData={true} />}>
          <Route index element={<DashIDAwsmo />} />
          <Route path="instancedetail" element={<AccountAWSID />} />
          <Route path="instancedetail/:instanceId" element={<InstanceDetail />} />
          <Route path="groups" element={<AwsmoGroups />} />
          <Route path="groups/create" element={<CreateAwsmoGroup />} />
          <Route path="groups/:groupId" element={<GroupDetail />} />
          <Route path="maintenance" element={<MaintenanceGroups />} />
          <Route path="reports" element={<Reports />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes >
    </>
  );
};

export default RoutesComponent;
