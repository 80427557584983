import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'src/redux/globalActions';
import toast from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { authService } from 'src/services/authService';

const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const token = useSelector((state) => state.user.token);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            if (token) {
                try {
                    const response = await authService.validate(token);
                    console.log('Token validation response:', response);
                    if (response !== 200) {
                        toast.error('Session expired. Please login again.');
                        performLogout();
                    }
                } catch (error) {
                    console.error('Token validation failed:', error);
                    toast.error('Session expired. Please login again.');
                    performLogout();
                }
            } else {
            }
            setIsLoading(false);
        };

        verifyToken();
    }, [token]);

    const performLogout = () => {
        dispatch(logout());
        toast.success('Logged out successfully.');
        navigate('/login');
    };

    return { isAuthenticated, isLoading, performLogout };
};

export default useAuth;