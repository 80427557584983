import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { awsService } from 'src/services/awsService';
import {
    Select, MenuItem, FormControl, InputLabel, Box, Button, IconButton,
    TextField, Typography, Paper, Stack, Grid, Tooltip, TableContainer,
    Table, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Switch, Chip
} from '@mui/material';
import InstancesList from './InstancesList';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import AwsmoGroupHistoryModal from './AwsmoGroupHistoryModal';

function GroupDetail() {
    const currentTimezone = moment.tz.guess();
    const timezoneLabel = moment.tz(currentTimezone).format('z');
    const [isGroupEditable, setGroupEditable] = useState(false);
    const [isInstancesEditable, setInstancesEditable] = useState(false);
    const [fetchedInstanceIds, setFetchedInstanceIds] = useState([]);
    const [selectedInstances, setSelectedInstances] = useState([]);
    const [maintenanceWindows, setMaintenanceWindows] = useState([]);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);
    const [groupDetails, setGroupDetails] = useState({ name: '', maintenance_windows: [], optimized: true });


    const formattedMaintenanceWindows = groupDetails.maintenance_windows.map(window => ({
        start: dayjs(window.start).toISOString(),
        end: dayjs(window.end).toISOString(),
    }));

    async function handleSave() {
        const payload = {
            aws_account_id: accountId,
            instance_ids: selectedInstances,
            name: groupDetails.name,
            maintenance_window_ids: groupDetails.maintenance_window_ids,
            optimized: groupDetails.optimized,
        };

        try {
            const responseStatus = await awsService.updateAwsmoGroup(groupId, payload, token);
            // Check if response status code is 200
            if (responseStatus === 200) {
                toast.success('Group updated successfully!');
                setGroupEditable(false);
                if (isInstancesEditable) {
                    setSelectedInstances([]);
                    setInstancesEditable(false);
                    setFetchFilters({ awsmo_group: groupId });
                    setVisibleColumns({
                        ec2InstanceId: true,
                        instanceState: true,
                        region: true,
                        instanceType: true,
                        status: true,
                        assignPriority: false,
                        autoscalingGroup: false,
                        action: false,
                        awsmoGroup: false,
                        checkbox: false,
                    });
                }
            } else {
                toast.error('Failed to update group. Please try again later.');
            }
        } catch (error) {
            console.error('Failed to update group:', error);
            toast.error('Failed to update group. Please try again later.');
        }
    }



    const handleCancelEdit = () => {
        if (isGroupEditable) {
            setGroupEditable(false);
        }
        if (isInstancesEditable) {
            setSelectedInstances([]);
            setInstancesEditable(false);
            setFetchFilters({ awsmo_group: groupId });
            setVisibleColumns({
                ec2InstanceId: true,
                instanceState: true,
                region: true,
                instanceType: true,
                status: true,
                assignPriority: false,
                autoscalingGroup: false,
                action: false,
                awsmoGroup: false,
                checkbox: false,
            });
        }
    };


    const handleSelectedRowsChange = (newSelected) => {
        setSelectedInstances(newSelected)
    };

    const token = useSelector((state) => state.user.token);
    const accountId = useSelector((state) => state.awsAccount.accountId);
    const navigate = useNavigate();
    const { groupId } = useParams();

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const groupData = await awsService.fetchAwsmoGroupDetails(groupId, token);
                const windowsData = await awsService.fetchMaintenanceWindows(token);

                // Find the maintenance windows that belong to this group
                const groupMaintenanceWindows = windowsData.filter(window =>
                    window.awsmo_groups.some(group => group.id === parseInt(groupId))
                );

                setGroupDetails({
                    ...groupData,
                    maintenance_windows: groupMaintenanceWindows,
                    maintenance_window_ids: groupMaintenanceWindows.map(window => window.id)
                });

                const instancesResponse = await awsService.fetchSavedInstances(accountId, token, { awsmo_group: groupId });
                console.log('instances respone --', instancesResponse);
                setFetchedInstanceIds(instancesResponse.map(instance => instance.InstanceId));
                setMaintenanceWindows(windowsData);
            } catch (error) {
                console.error('Failed to fetch group details or instances:', error);
            }
        };

        fetchDetails();
    }, [accountId, groupId, token]);

    const toggleGroupEdit = () => {
        setGroupEditable(!isGroupEditable);
    };
    const handleBack = () => navigate(-1);

    const formatRepeatsEvery = (repeatsEvery) => {
        if (repeatsEvery === 'ALTERNATE_WEEK') {
            return 'Alternate Week';
        }
        else if (repeatsEvery === 'NEVER') {
            return 'Never';
        }
        else if (repeatsEvery === 'WEEK') {
            return 'Week';
        }
        else if (repeatsEvery === 'MONTH') {
            return 'Month';
        }
    };


    const [fetchFilters, setFetchFilters] = useState({ awsmo_group: groupId });
    const [visibleColumns, setVisibleColumns] = useState({
        ec2InstanceId: true,
        instanceState: true,
        region: true,
        instanceType: true,
        status: true,
        assignPriority: false,
        autoscalingGroup: false,
        action: false,
        awsmoGroup: false,
        checkbox: false,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setGroupDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };


    const handleInstancesEditClick = () => {
        setInstancesEditable(isInstancesEditable => {
            if (isInstancesEditable) {
                setSelectedInstances([]);
                setFetchFilters({ awsmo_group: groupId });
                setVisibleColumns({
                    ec2InstanceId: true,
                    instanceState: true,
                    region: true,
                    instanceType: true,
                    status: true,
                    assignPriority: false,
                    autoscalingGroup: false,
                    action: false,
                    awsmoGroup: false,
                    checkbox: false,
                });

                return false;
            } else {
                setFetchFilters(prevFilters => ({ ...prevFilters, awsmo_group: `${groupId},null` }));
                setVisibleColumns(prevColumns => ({ ...prevColumns, awsmoGroup: true, checkbox: true }));
                setSelectedInstances(fetchedInstanceIds);
                return true;
            }
        });
    };

    return (
        <>
            <Box className='margin-top-dash font-open'>
                <Box className='h-scroller rightContainer'>
                    <Box className='m-3 m-lg-4'>
                        <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
                            <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleBack}>
                                Back
                            </Button>
                        </Stack>
                        <Paper sx={{ p: 3, borderRadius: 2, position: 'relative', mb: 3 }}>
                            <Grid container spacing={2} >
                                {
                                    isGroupEditable ? (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                margin="normal"
                                                id="name"
                                                name="name"
                                                label="Group Name"
                                                value={groupDetails.name}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} md={6}>
                                            <Stack spacing={1}>
                                                <Typography variant="subtitle1" component="div" fontWeight="bold">
                                                    Group Name
                                                </Typography>
                                                <Typography variant="h7" gutterBottom>
                                                    {groupDetails.name}
                                                </Typography>
                                                <Typography variant="subtitle1" component="div" fontWeight="bold">
                                                    Load Balancer ARN
                                                </Typography>
                                                <Typography variant="h7" >
                                                    {groupDetails.load_balancer}
                                                </Typography>
                                            </Stack>

                                        </Grid>
                                    )
                                }
                                <AwsmoGroupHistoryModal
                                    open={openHistoryModal}
                                    onClose={() => setOpenHistoryModal(false)}
                                    awsAccountId={accountId}
                                    groupId={groupId}
                                    token={token}
                                />

                                {isGroupEditable ? (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={groupDetails.optimized}
                                                    onChange={(event) => setGroupDetails({ ...groupDetails, optimized: event.target.checked })}
                                                    name="optimized"
                                                />
                                            }
                                            label="Optimized"
                                        />
                                    </Grid>
                                ) : (

                                    <Grid item xs={12} md={6}>
                                        <Stack spacing={1}>
                                            <Typography variant="subtitle1" component="div" fontWeight="bold">
                                                Optimized
                                            </Typography>
                                            <Typography variant="h7" gutterBottom>
                                                {groupDetails.optimized ? 'Yes' : 'No'}
                                            </Typography>
                                        </Stack>

                                    </Grid>
                                )}
                                {isGroupEditable ? (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="maintenance-window-label">Maintenance Window</InputLabel>
                                            <Select
                                                labelId="maintenance-window-label"
                                                name="maintenance_window_ids"
                                                multiple
                                                value={groupDetails.maintenance_window_ids}
                                                onChange={handleChange}
                                                label="Maintenance Window"
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((id) => (
                                                            <Chip key={id} label={maintenanceWindows.find(window => window.id === id)?.name || id} />
                                                        ))}
                                                    </Box>
                                                )}
                                            >
                                                {maintenanceWindows.map((window) => (
                                                    <MenuItem key={window.id} value={window.id}>
                                                        {window.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} >
                                        <div className='dash-table'>
                                            <Box sx={{ width: '100%' }}>
                                                <Typography variant="subtitle1" component="div" fontWeight="bold" sx={{ mb: 2 }}>
                                                    Maintenance Windows ({timezoneLabel})
                                                </Typography>
                                                <Paper sx={{ width: '100%', mb: 2 }}>
                                                    <TableContainer>
                                                        <Table sx={{ minWidth: 450 }} aria-labelledby="tableTitle" size={'medium'}>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold', py: 1, backgroundColor: 'rgba(0, 0, 0, 0.05)', width: '20%' }}>Name</TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold', py: 1, backgroundColor: 'rgba(0, 0, 0, 0.05)', width: '30%' }}>Start</TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold', py: 1, backgroundColor: 'rgba(0, 0, 0, 0.05)', width: '30%' }}>End</TableCell>
                                                                    <TableCell align="center" sx={{ fontWeight: 'bold', py: 1, backgroundColor: 'rgba(0, 0, 0, 0.05)', width: '20%' }}>Repeats Every </TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {groupDetails.maintenance_windows.map((window) => (
                                                                    <TableRow key={window.id}>
                                                                        <TableCell align="center" sx={{ width: '20%' }}>{window.name}</TableCell>
                                                                        <TableCell align="center" sx={{ width: '30%' }}>{moment(window.start).format('D MMM YYYY HH:mm')}</TableCell>
                                                                        <TableCell align="center" sx={{ width: '30%' }}>{moment(window.end).format('D MMM YYYY HH:mm')}</TableCell>
                                                                        <TableCell align="center" sx={{ width: '20%' }}>{formatRepeatsEvery(window.repeats_every)}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                            </Box>
                                        </div>
                                    </Grid>
                                )}


                                {!isGroupEditable && (
                                    <Tooltip title="Edit Group Details">
                                        <IconButton
                                            onClick={toggleGroupEdit}
                                            sx={{ position: 'absolute', top: 8, right: 8 }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Button
                                    startIcon={<HistoryIcon />}
                                    onClick={() => setOpenHistoryModal(true)}
                                    sx={{ position: 'absolute', top: 60, right: 10 }}
                                >
                                    Show History
                                </Button>
                            </Grid>
                        </Paper>
                        <InstancesList
                            onSelectionChange={handleSelectedRowsChange}
                            fetchFilters={fetchFilters}
                            visibleColumns={visibleColumns}
                        />
                        {(isGroupEditable || isInstancesEditable) && (
                            <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancelEdit}
                                >
                                    Cancel
                                </Button>
                            </Stack>
                        )}
                    </Box>
                </Box>
            </Box>
        </>

    );
}

export default GroupDetail;
