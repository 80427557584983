import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, Chip, FormControl, InputLabel, MenuItem,
    Paper, Select, Stack, Switch, TextField, Typography,
    FormControlLabel, LinearProgress
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import toast from 'react-hot-toast';
import MaintenanceWindowModal from './MaintenanceWindowModal';
import { awsService } from 'src/services/awsService';
import { Grid } from '@mui/material';



function CreateAwsmoGroup() {
    const navigate = useNavigate();
    const awsAccount = useSelector((state) => state.awsAccount);
    const token = useSelector((state) => state.user.token);
    const region = useSelector((state) => state.awsAccount.region);
    const [groupDetails, setGroupDetails] = useState({
        maintenance_window_ids: [],
        optimized: true,
        name: '',
        load_balancer_arn: '',
    });
    const [loadBalancers, setLoadBalancers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [maintenanceWindows, setMaintenanceWindows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        const fetchLoadBalancers = async () => {
            setIsLoading(true);
            try {
                const data = await awsService.fetchLoadBalancers(token, region);
                setLoadBalancers(data.filter(lb => lb.awsmo_group_id === null));
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error('Failed to fetch load balancers.');
            }
        };

        const fetchMaintenanceWindows = async () => {
            try {
                const data = await awsService.fetchMaintenanceWindows(token);
                setMaintenanceWindows(data);
            } catch (error) {
                toast.error('Failed to fetch maintenance windows.');
            }
        };

        if (!isModalOpen) {
            fetchMaintenanceWindows();
        }
        fetchLoadBalancers();

    }, [token, isModalOpen, region]);


    const handleSaveGroup = async () => {
        if (!groupDetails.load_balancer_arn) {
            toast.error('Please select a load balancer.');
            return;
        }

        const payload = {
            aws_account_id: awsAccount.accountId,
            load_balancer_arn: groupDetails.load_balancer_arn,
            name: groupDetails.name,
            maintenance_window_ids: groupDetails.maintenance_window_ids,
            optimized: groupDetails.optimized,
        };
        try {
            const data = await awsService.createAwsmoGroup(payload, token);
            toast.success('Group created successfully!');
            // Reset the form fields to default values
            setGroupDetails({
                maintenance_window_ids: [],
                optimized: true,
                name: '',
                load_balancer_arn: '',
            });
        } catch (error) {
            toast.error('Failed to create group. Please try again.');
        }
    };

    const handleChange = (e) => {
        setGroupDetails({ ...groupDetails, [e.target.name]: e.target.value });
    };

    const handleOptimizedToggle = (event) => {
        setGroupDetails({ ...groupDetails, optimized: event.target.checked });
    };

    const canSave = groupDetails.name.length > 0 && groupDetails.load_balancer_arn;

    return (
        <Box className='margin-top-dash font-open'>
            <Box className='h-scroller rightContainer'>
                <Box className='m-3 m-lg-4'>
                    <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
                        <Button startIcon={<ArrowBackIosNewIcon />} onClick={() => navigate(-1)}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canSave}
                            onClick={handleSaveGroup}
                            borderRadius={3}
                        >
                            Save Group
                        </Button>
                    </Stack>
                    <Paper elevation={4} sx={{ p: 3, borderRadius: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Create New Group</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        required
                                        name="name"
                                        label="Group Name"
                                        value={groupDetails.name}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="load-balancer-label">Load Balancer</InputLabel>
                                    <Select
                                        labelId="load-balancer-label"
                                        name="load_balancer_arn"
                                        value={groupDetails.load_balancer_arn}
                                        onChange={handleChange}
                                        label="Load Balancer"
                                    >
                                        {isLoading ? (
                                            <MenuItem disabled style={{ height: 40 }}>
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgress />
                                                </Box>
                                            </MenuItem>
                                        ) : loadBalancers.length > 0 ? (
                                            loadBalancers.map((lb) => (
                                                <MenuItem key={lb.id} value={lb.LoadBalancerArn}>
                                                    {lb.LoadBalancerArn}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="" disabled>
                                                No load balancers available
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={groupDetails.optimized}
                                            onChange={handleOptimizedToggle}
                                            name="optimized"
                                        />
                                    }
                                    label="Optimized"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="maintenance-window-label">Maintenance Window</InputLabel>
                                    <Select
                                        labelId="maintenance-window-label"
                                        name="maintenance_window_ids"
                                        multiple
                                        value={groupDetails.maintenance_window_ids}
                                        onChange={handleChange}
                                        label="Maintenance Window"
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={maintenanceWindows.find(window => window.id === value)?.name || value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {maintenanceWindows.map((window) => (
                                            <MenuItem key={window.id} value={window.id}>
                                                {window.name}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="create-new">
                                            <em onClick={openModal}> + Create New Maintenance Window</em>
                                        </MenuItem>
                                    </Select>

                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <MaintenanceWindowModal open={isModalOpen} handleClose={closeModal} window={null} />
                </Box>
            </Box>
        </Box>
    );
}

export default CreateAwsmoGroup;
