import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingScreen from 'src/components/dashboard/Loading';
import Dashboard from 'src/components/dashboard/Dashboard';
import Processing from 'src/components/info/Processing';
import useAuth from 'src/hooks/useAuth';
import useEnsureData from 'src/hooks/useEnsureData';
import useCheckProcessedStatus from 'src/hooks/useCheckProcessedStatus';

const ProtectedRoute = ({ component: Component, needsData = false }) => {
    const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
    const { isLoading: isLoadingData, loadingMessage } = useEnsureData(isAuthenticated && needsData);
    const processedStatus = useSelector(state => state.user.processedStatus);
    const { isProcessing } = useCheckProcessedStatus();

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }
    if (isLoadingAuth || isLoadingData) {
        return <LoadingScreen message={loadingMessage} />;
    }
    return (
        <>
            <Component />
            {!processedStatus && isProcessing && <Processing />}
        </>
    );
};

export default ProtectedRoute;
